.Button {
    display: inline-block;
    border-radius: 4px;
    color:  #FFF;
    padding:  15px 30px;
    text-shadow: none;
    font-size:  18px;
    cursor: pointer;
    border:  0;
    font-family: inherit;
}

.Button:hover {
    opacity:  0.8;
}

.Button--colour-red {
    background-color:  #BC2354;
}

.Button--colour-darkblue {
    background-color:  #2A3042;
}


.Button--size-small {
    padding:  10px 20px;
    font-size:  14px;
}


/* Loading state
 ----------------------------------------------*/

.Button--loading {
    background-image:
        repeating-linear-gradient(
          -45deg,
          #F6B21F,
          #F6B21F 1rem,
          #F3A600 1rem,
          #F3A600 2rem
        );
  background-size: 200% 200%;
  animation: loadingButton 5s linear infinite;
}

@keyframes loadingButton {
  100% {
    background-position: 100% 100%;
  }
}
