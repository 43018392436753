.FaqsSection {
    background-color:  #F2F5F5;
    padding:  75px 0;
}

.FaqsSection__items {
    margin-top: 50px;
}

.FaqsSection__chat {
    text-align: center;
    margin-top:  50px;
}

.FaqsSection__items__item {
    margin-bottom:  10px;
}


@media only screen and (min-width: 650px) {

   .FaqsSection__items {
        display:  grid;
        grid-gap:  30px;
        grid-row-gap: 30px;
        grid-template-columns: repeat( 2, 1fr );
    }

    .FaqsSection__items__item {
        margin-bottom:  0;
    }


}
