


.EventScreen__tickettypes {
    background-color:  #F3F5F7;
    padding-top:  30px;
    padding-bottom:  30px;
}

.EventScreen__tickettypes__item {
    background-color:  #FFF;
    padding:  10px;
    margin-bottom:  10px;
}

@media only screen and (min-width: 600px) {

    .EventScreen__hero {
        height:  550px;
    }

}
