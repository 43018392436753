
.Modal {
    background-color:  #FFF;
    padding:  50px;
    font-weight:  700;
    border-radius: 4px;
    line-height: 140%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
    z-index:  200;
    max-width:  1000px;
}

.Modal__body {
    max-height:  500px;
    overflow-x: scroll;
}


.Modal__close {
    border-radius: 50px;
    border:  2px solid #02A4AE;
    padding:  5px 10px;
    color:  #02A4AE;
    margin-top:  50px;
    font-size:  14px;
    font-weight:  600;
    cursor: pointer;
    text-align: center;
}
