.AccountOrdersScreen__item {
    display:  flex;
    justify-content: space-between;
    border:  1px solid #DDD;
    border-radius: 6px;
    padding:  10px;
    margin-top:  30px;
    color:  #2A3042;
}

.AccountOrdersScreen__item:hover {
    transform: scale(1.05);
}
