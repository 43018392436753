
.Header {
    padding-top: 30px;
    padding-bottom: 30px;
}

.Header .Container {
    display:  flex;
    align-items: center;
    justify-content: space-between;
}

.Header__body {
    display:  flex;
}

.Header__footer {
    display:  flex;
    align-items: center;
}

.Header__logo {
    width:  150px;
    padding-right:  30px;
    margin-right:  30px;
}

.Header__account {
    color:  inherit;
    margin-right:  10px;
    font-size:  14px;
}
