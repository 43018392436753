.ActionBarSection {
    text-align: center;
    background-color: #02A4AE;
    padding-top:  30px;
    padding-bottom: 30px;
}

.ActionBarSection .Button {
    display:  block;
    margin-bottom:  10px;
}

.ActionBarSection .Button:last-child {
    margin-bottom:  0;
}




@media only screen and (min-width: 600px) {


    .ActionBarSection {
        display: flex;
        justify-content: center;
    }


    .ActionBarSection .Button {
        display: inline-block;
        margin:  0 20px 0 0;
    }




}
