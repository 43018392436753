
.Footer {
    padding-top:  30px;
    padding-bottom:  30px;
    border-top:  1px solid #E7E7E7;
}

.Footer .Container {
    display:  flex;
    justify-content: space-between;
    align-items: center;
}

.Footer__logo {
    width:  120px;
}
