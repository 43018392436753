.AccommodationSection {
    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:  #F2F5F5;
}

.AccommodationSection .Container {
    height:  720px;
}

.AccommodationSection__items {
    display:  flex;
    margin-top:  60px;
}
