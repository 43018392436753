

.FaqItem {
    border:  1px solid #DCE7E7;
    border-radius: 4px;
    background-color: #FFF;
}

.FaqItem__question {
    padding:  15px;
    cursor: pointer;
    background-image: url('arrow-closed.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
}

.FaqItem__question--open {
    background-image: url('arrow-open.svg');
}

.FaqItem__answer {
    border-top:  1px solid #DCE7E7;
    padding:  15px;
}
