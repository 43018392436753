.ImageHeaderSection {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height:  350px;
}


@media only screen and (min-width: 650px) {

    .ImageHeaderSection {
        height:  700px;
    }


}
