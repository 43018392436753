
.CouponForm__handle {
    font-size: 13px;
    cursor: pointer;
    border:  1px solid #02A4AE;
    padding:  5px 10px;
    border-radius: 50px;
    color:  #02A4AE;
}

.CouponForm__handle:hover {
    opacity: 0.7;
}

.CouponForm .Button {
    margin-left: 10px;
}

.CouponForm__error {
    color:  #BC2354;
    font-weight:  bold;
    font-size:  13px;
    margin-top:  10px;
}
