.TicketOptionsSection {
    background-image: url('background.webp');
    background-size:  cover;
    background-position: center top;
    text-align: center;
    padding:  75px 0;
}

.TicketOptionsSection__items {
    margin-top:  60px;
}

.TicketOptionsSection__items__item {
    width:  300px;
    background-color:  #FFF;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0 0 20px 20px rgba(0,0,0,0.40);
    padding:  120px 20px 20px 20px;
    background-image:  url('ticket-icon.svg');
    background-repeat: no-repeat;
    background-position: top 40px center;
    text-align: center;
    margin:  0 auto 50px;
}

.TicketOptionsSection__items__item__name {
    font-size:  20px;
    font-weight:  600;
}

.TicketOptionsSection__items__item__price {
    font-size:  26px;
    font-weight:  600;
    margin-top:  10px;
}

.TicketOptionsSection__items__item__info {
    opacity:  0.5;
    margin-top:  10px;
}

.TicketOptionsSection__items__item__button {
    margin-top:  40px;
}

.TicketOptionsSection__items__item__button .Button {
    width:  100%;
}



@media only screen and (min-width: 650px) {


    .TicketOptionsSection {
        display:  flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:  0;
    }

    .TicketOptionsSection .Container {
        height:  720px;
    }

    .TicketOptionsSection__items {
        display:  flex;
    }

    .TicketOptionsSection__items__item {
        margin-right:  100px;
    }

    .TicketOptionsSection__items__item:last-child {
        margin-right:  0;
    }

}
