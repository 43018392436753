
.AccommodationSection__items__item {
    width:  410px;
    margin-right:  30px;
    cursor: pointer;
}

.AccommodationSection__items__item:hover {
    opacity:  0.75;
}

.AccommodationSection__items__item:last-child {
    margin-right:  0;
}


.AccommodationSection__items__item__photo {
    background-color:  #BBB;
    height:  260px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.AccommodationSection__items__item__name {
    font-size:  20px;
    margin:  5px 0;
}

.AccommodationSection__items__item__miles,
.AccommodationSection__items__item__address {
    margin:  5px 0;
    opacity: 0.5;
}

.AccommodationSection__items__item__details {
    margin-top:  30px;
    border-top:  1px solid #E7E7E7;
    padding-top:  30px;
}
