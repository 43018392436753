.IntroductionSection {
    text-align:  center;
    padding-top:  100px;
    padding-bottom:  100px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.IntroductionSection__title {
    font-family: SusieScriptWeb;
    font-size:  60px;
}

.IntroductionSection__text {
    max-width:  750px;
    margin:  0 auto;
}

.IntroductionSection__signoff {
    margin-top:  20px;
    font-family: SusieScriptWeb;
    font-size:  60px;
    color:  #BC2354;
    transform: rotate(-4deg);
}
