

.EventList__item {
    margin:  30px 0;
}

.EventList__item {
    display: block;
    border:  1px solid #E2E2E2;

    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
}

.EventList__item:hover {
    transform: scale(1.05);
    opacity: 0.9;
}

.EventList__item__header {
    background-color:  #666;
    height:  250px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: cover;
    background-position: center;
}

.EventList__item__body {
    display: flex;
    justify-content: space-between;
    padding:  20px;
    color:  #2A3042;
}

.EventList__item__name {
    font-size:  16px;
}

.EventList__item__date {
    opacity: 0.6;
}
