
.Overlay {
    position:  fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top:  0;
    bottom:  0;
    left:  0;
    right:  0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index:  100;
}