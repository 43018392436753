.VenueSection {
    padding:  75px 0;
    text-align: center;
}

.VenueSection__address {
    margin-top:  20px;
    opacity:  0.75;
}

.VenueSection__map {
    margin-top:  50px;
    width:  100%;
    height:  650px;
}

.VenueSection__clickformap {
    text-align: center;
    margin-top:  50px;
}
