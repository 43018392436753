.EventNavigation__list {
    display:  none;
}

.EventNavigation__list__item__link {
    display: block;
    padding:  10px 20px;
    color:  inherit;
    transition: none;
    border:  1px solid #FFF;
    cursor: pointer;
}

.EventNavigation__list__item__link:hover {
    border:  1px solid #E4E4E4;
}




@media only screen and (min-width: 650px) {

    .EventNavigation__list {
       display:  flex;
    }
}
