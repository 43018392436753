
.BasketExpiryTimer {
    margin:  0 auto 40px;
}

.BasketExpiryTimer__body {
    display: flex;
    justify-content: center;
    color:  #BC2354;
    font-weight:  bold;
    padding: 10px 15px;
    background-color: #F4DCE3;
    border-radius: 8px;
}
