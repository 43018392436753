
@font-face {
  font-family: SusieScriptWeb;
  src: url('Susie-Script.woff');
}

@font-face {
  font-family: GothamBookWeb;
  src: url('Gotham-Book.woff');
}

@font-face {
  font-family: GothamMediumWeb;
  src: url('Gotham-Medium.woff');
}

* {
    margin:  0;
    padding:  0;
    list-style-type: none;
    transition: all 0.5s ease;
    box-sizing: border-box;
}


body {
    font-family:  'GothamBookWeb', sans-serif;
    color:  #2A3042;
}


a {
    text-decoration: none;
    color:  #02A4AE;
}

p {
    line-height: 150%;
}


strong, b {
    font-family: 'GothamMediumWeb', sans-serif;
}


.form__field {
    margin-top:  20px;
}

.form__field__label {
    display: block;
    margin-bottom:  5px;
}

.form__field__input {
    width:  100%;
    padding:  10px;
    font-size:  15px;
    font-weight:  bold;
    border:  1px solid #CCC;
}

.form__field__input--short {
    width:  180px;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #AAA;
  font-style: italic;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #AAA;
  font-style: italic;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #AAA;
  font-style: italic;
}
:-moz-placeholder { /* Firefox 18- */
  color: #AAA;
  font-style: italic;
}
