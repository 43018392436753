
.Container {
    display:  inherit;
    align-items: inherit;
    justify-content: inherit;
    flex-direction: inherit;
    width:  100%;
    max-width:  1250px;
    margin:  0 auto;
    padding-left:  20px;
    padding-right:  20px;
}


.Container--width-narrow {
    max-width:  640px;
    margin-top:  50px;
    margin-bottom:  100px;
}
