.TicketQuantityControl {
    display:  flex;
    align-items: center;

}

.TicketQuantityControl__change {
    background-size:  cover;
    height : 15px;
    width:  15px;
    cursor: pointer;
}

.TicketQuantityControl__change--minus {
    background-image: url('minus.svg');
}

.TicketQuantityControl__change--plus {
    background-image: url('plus.svg');
}

.TicketQuantityControl__change--disabled {
    opacity: 0.3;
}

.TicketQuantityControl__quantity {
    font-size:  30px;
    font-weight:  500;
    margin:  0 20px;
}
