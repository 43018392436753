

.SummaryScreen__ambassador {
    border-top: 1px solid #E0E0E0;
    margin-top:  100px;
}
.SummaryScreen__ambassador__header {
    font-weight:  600;
    padding:  20px 0 0;
    opacity:  0.6;
}

.SummaryScreen__ambassador__body {
    padding:  10px 0 20px 0;
    line-height: 140%;
}

.SummaryScreen__tickets {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}



.SummaryScreen__tickets__item {
    display:  flex;
    justify-content: space-between;
}

.SummaryScreen__tickets__item__number {
    flex:  0 1 30px;
    padding:  20px 0;
    opacity:  0.6;
}

.SummaryScreen__tickets__item__name {
    flex:  1;
    padding:  20px 0;
    line-height: 140%;
}

.SummaryScreen__tickets__item__attendeeName {
    margin-left:  15px;
    opacity: 0.7;
    line-height: inherit;
}

.SummaryScreen__tickets__item__price {
    padding:  20px 0;
}


.SummaryScreen__summary {
    display: flex;
    justify-content: space-between;
    padding:  20px 0;
}

.SummaryScreen__summary__totals {
    text-align: right;
}
