
.Loading {
    display: flex;
    align-items: center;
    color: #777;
    font-weight: 500;
}

.Loading--align-center {
    justify-content: center;
}

.Loading__spinner {
    background-image:  url('spinner.svg');
    width: 50px;
    height: 50px;
    background-size: cover;
    margin-right: 10px;
}


.Loading--size-large .Loading__spinner {
    width: 100px;
    height: 100px;
}
