.SectionTitle {
    font-size:  32px;
    color:  #2A3042;
    background-repeat: no-repeat;
    background-position: top center;
    background-size:  50px 50px;
    text-align: center;
}

.SectionTitle--icon {
    padding-top:  60px;
}

.SectionTitle--colour-white {
    color:  #FFF;
}

.SectionTitle--icon-tickets {
    background-image: url('icon-tickets.svg');
}

.SectionTitle--icon-accommodation {
    background-image: url('icon-accommodation.svg');
}

.SectionTitle--icon-venue {
    background-image: url('icon-venue.svg');
}

.SectionTitle--icon-faqs {
    background-image: url('icon-faqs.svg');
}

