
.AttendeeForm {

}

.AttendeeForm__attributes {
    display:  flex;
    margin-top:  10px;
}


.AttendeeForm__attributes__item {
    cursor: pointer;
    background-size: 20px;
    background-repeat:  no-repeat;
    padding:  5px 5px 5px 25px;
    font-size:  12px;
    color:  #00A5AE;
    font-weight: bold;
    margin-right:  20px;
}


.AttendeeForm__attributes__item--diet {
    background-image:  url('diet.svg');
}

.AttendeeForm__attributes__item--allergies {
    background-image:  url('allergies.svg');
}

.AttendeeForm__attributes__item--accessibility {
    background-image:  url('accessibility.svg');
}

.form__field__label__required {
    color: #BC2354;
    font-size:  11px;
    margin-left: 20px
}
