
.TicketTypeSelector {
    margin-top: 50px;
}

.TicketTypeSelector__item {
    display:  flex;
    align-items: center;
    justify-content: space-between;
    border:  1px solid #E2E2E2;
    margin-bottom:  20px;
    background-color:  #F2F5F5;
    padding:  20px;
    border-radius: 4px;
}


.TicketTypeSelector__item__name {
    font-size:  20px;
}


.TicketTypeSelector__item__price {
    font-size:  20px;
}

.TicketTypeSelector__item__quantity__limit {
    color:  #BC2354;
    text-align: center;
}
