
.AmbassadorScreen__line {
    margin:  15px 0;
    border-bottom:  1px solid #DDD;
}

.AmbassadorScreen__line__header {
    padding:  5px 0;
}

.AmbassadorScreen__line__body {
    font-weight:  bold;
    padding:  5px 0;
}
